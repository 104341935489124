import {
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  ON_LOGGEDIN,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS
} from "../actions/LoginAction";

import jwtDecode from "jwt-decode";

const initialState = {
  email: "",
  password: "",
  isLoggedin: false,
};


const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EMAIL: {
      return {
        ...state,
        email: action.payload,
        loginError: '',
      };
    }

    case UPDATE_PASSWORD: {
      return {
        ...state,
        password: action.payload,
        loginError: '',
      };
    }

    case ON_LOGGEDIN: {
      return {
        ...state,
        isLoggedin: action.payload,
      };
    }

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedin: true,
        user: jwtDecode(action.token),
        token: action.token,
      }
    case LOGIN_FAILURE:
      console.log('LOGIN_FAILURE')
      return {
        ...state,
        isLoggedin: false,
        loginError: 'Invalid Credentials'
      }

    default:
      const {user, token} = getUserFromLocalStorage();
      if (user) {
        return {
          ...state,
          isLoggedin: true,
          user: user,
          token: token,
        }
      }
      return { ...state };
  }
};

const getUserFromLocalStorage = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return {
      token: token,
      user: jwtDecode(token)
    };
  }
  return {user:null, token: null};
}

export default loginReducer;
