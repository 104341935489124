import apiClient from "./apiClient";
import config from "../config/api";

const dailyRegistrations = (token, howManyDays = 30) => {
    apiClient.setHeader('x-admin-jwt', JSON.stringify(token));
    return apiClient.post(config.ENDPOINTS.DAILY_REGISTRATIONS_CHART, {howmanydays: howManyDays});
}

const dailySales = (token, howManyDays = 30) => {
    apiClient.setHeader('x-admin-jwt', JSON.stringify(token));
    return apiClient.post(config.ENDPOINTS.DAILY_SALES_CHART, {howmanydays: howManyDays});
}

const dailyRevenue = (token, howManyDays=30) => {
    apiClient.setHeader('x-admin-jwt', JSON.stringify(token));
    return apiClient.post(config.ENDPOINTS.DAILY_REVENUE_CHART, {howmanydays: howManyDays});
}

const dailySpent = (token, howManyDays = 30) => {
    apiClient.setHeader('x-admin-jwt', JSON.stringify(token));
    return apiClient.post(config.ENDPOINTS.DAILY_SPENT_CHART, {howmanydays: howManyDays});
}

const dailySubscriptionRevenue = (token, howManyDays=30) => {
    apiClient.setHeader('x-admin-jwt', JSON.stringify(token));
    return apiClient.post(config.ENDPOINTS.DAILY_SUBSCRIPTION_REVENUE_CHART, {howmanydays: howManyDays});
}

const dailyNewSubscriptionsCount = (token, howManyDays=30) => {
    apiClient.setHeader('x-admin-jwt', JSON.stringify(token));
    return apiClient.post(config.ENDPOINTS.DAILY_NEW_SUBSCRIPTIONS_COUNT_CHART, {howmanydays: howManyDays});
}

const dailySubscriptionsCancelledCount = (token, howManyDays=30) => {
    apiClient.setHeader('x-admin-jwt', JSON.stringify(token));
    return apiClient.post(config.ENDPOINTS.DAILY_SUBSCRIPTION_CANCELLATIONS_CHART, {howmanydays: howManyDays});
}

export default {
    dailyRegistrations: dailyRegistrations,
    dailySales: dailySales,
    dailyRevenue: dailyRevenue,
    dailySpent: dailySpent,
    dailySubscriptionRevenue: dailySubscriptionRevenue,
    dailyNewSubscriptionsCount: dailyNewSubscriptionsCount,
    dailySubscriptionsCancelledCount: dailySubscriptionsCancelledCount,
}