import React from "react";
import {connect} from "react-redux";
import * as echarts from "echarts";
import PageHeader from "../../../components/PageHeader";
import {Dropdown} from "react-bootstrap";

import statsApi from "../../../api/stats"

class DailySpent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            whiteLabels: [],
            howManyDays: 30,
            dates: {},
            isLoading: true,
        };


    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initData();

        const title = document.getElementsByTagName('title')[0];
        title.innerHTML = 'Dashboard::User Metrics::Daily Coins Spent Charts'

    };

    initData = () => {

        const {token} = this.props;

        statsApi.dailySpent(token, this.state.howManyDays).then(response => {
            if (response.ok && response.data.data) {
                const whiteLabels = [];
                for ( const property in response.data.data.spent ) {
                    whiteLabels.push(property);
                    const whiteLabelNoDot = property.split('.').join("");
                    this[whiteLabelNoDot + 'Ref'] = null;
                    this[whiteLabelNoDot + 'Chart'] = null;
                    this['set'+ whiteLabelNoDot[0].toUpperCase() + whiteLabelNoDot.substring(1) + 'Ref'] = element => {
                        if (element) {
                            this[whiteLabelNoDot + 'Ref'] = element;
                            this[whiteLabelNoDot + 'Chart'] = echarts.init(this[whiteLabelNoDot + 'Ref']);
                        }
                    }
                }

                this.setState({
                    data: response.data.data.spent,
                    dates: response.data.data.dates,
                    whiteLabels: whiteLabels,
                    isLoading: false,
                }, this.initChartData);
            }
        });
    }


    initChartData = () => {

        for ( const property in this.state.data) {
            const series = [];

            Object.keys(this.state.data[property]).forEach((element, index) => {
                series.push({
                    type: "line",
                    name: element,
                    data: this.state.data[property][element],
                    symbolSize: 8,
                    smooth: true,
                });
            });

            const options = this.initChartOptions(series);
            const whiteLabelNoDot = property.split('.').join("");
            try {
                this[whiteLabelNoDot + 'Chart'].clear();
                this[whiteLabelNoDot + 'Chart'].setOption(options);
            } catch (e) {
                
            }


        }
    }

    initChartOptions = (series) => {
        return {
            tooltip: {
                trigger: "axis",
            },
            grid: {
                top: "4%",
                left: "1%",
                right: "1%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    boundaryGap: false,
                    data: this.state.dates,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#a9a9a9",
                        },
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ["#E0E6F1"],
                        },
                    },

                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: function (value, index) {
                            if (value > 0) {
                                if (value > 1000000) {
                                    return new Intl.NumberFormat('en-EN').format(value / 1000000) + ' M';
                                }
                                if (value > 1000) {
                                    return new Intl.NumberFormat('en-EN').format(value / 1000) + ' K';
                                }
                                return new Intl.NumberFormat('en-EN').format(value)
                            } else {
                                return 0;
                            }
                        },
                        textStyle: {
                            color: "#a9a9a9",
                        },
                    },
                },
            ],
            series: series,
        }
    }

    handleHowManyDaysChange = (howManyDays) => {
        this.setState({
            howManyDays: howManyDays,
            isLoading: true
        }, this.initData)
    }

    render() {
        const {data, howManyDays, whiteLabels, isLoading} = this.state;

        const charts = whiteLabels.map((whiteLabel) => (

                        <div className="row clearfix" key={"key_"+whiteLabel}>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className='float-left'>Daily Coins Spent ({whiteLabel === 'Total' ? 'All Labels': whiteLabel}) -</h2>

                                        <Dropdown as="span" className="float-left">
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="h2"
                                                id="dropdown-registrations-days"
                                            >
                                                - Last {this.state.howManyDays} Days

                                                <Dropdown.Menu
                                                    as="ul"
                                                    className="dropdown-menu dropdown-menu-left"
                                                >
                                                    <li className="dropdown-item"  onClick={() => this.handleHowManyDaysChange(30)}><a>Last 30 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleHowManyDaysChange(60)}><a>Last 60 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleHowManyDaysChange(90)}><a>Last 90 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleHowManyDaysChange(120)}><a>Last 120 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleHowManyDaysChange(180)}><a>Last 180 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleHowManyDaysChange(360)}><a>Last 360 days</a></li>

                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                    <div className="body gender-overview">
                                        <div id={"daily_spent_"+whiteLabel+"_chart"} ref={this['set' + whiteLabel.split('.').join("")[0].toUpperCase() + whiteLabel.split('.').join("").substring(1) + "Ref"]} whitelabel={whiteLabel} style={{height: 300}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>


        ));


        return (
    <div
    style={{flex: 1}}
        onClick={() => {
            document.body.classList.remove("offcanvas-active");
        }}
    >
    <div>
        <div className="container-fluid">
            <PageHeader
        HeaderText="Dashboard"
        Breadcrumb={[{name: "User Metrics - Daily Spent "}]}
        />
            { isLoading && ( <div>
                Loading...
            </div>)}
            { !isLoading && (charts)}
        </div>
    </div>
    </div>
        )
    }

}
const mapStateToProps = ({loginReducer}) => ({
    isLoggedin: loginReducer.isLoggedin,
    user: loginReducer.user,
    token: loginReducer.token
});

export default connect(mapStateToProps, {})(DailySpent);