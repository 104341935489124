import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/logo-white.svg";
import { updateEmail, updatePassword,onLoggedin, login, loginSuccess } from "../actions";

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoad: true,
      from: '/usermetricsdaily'
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoad: false
      })
    }, 500);
    document.body.classList.remove("theme-cyan");
    document.body.classList.remove("theme-purple");
    document.body.classList.remove("theme-blue");
    document.body.classList.remove("theme-green");
    document.body.classList.remove("theme-orange");
    document.body.classList.remove("theme-blush");
    const {location} = this.props;
    if (location && location.state && location.state.from && location.state.from.pathname && location.state.from.pathname !== '/login') {
      this.setState({ from: location.state.from.pathname});
    }
    const title = document.getElementsByTagName('title')[0];
    title.innerHTML = 'Dashboard::Login'
  }

  handleKeyPress = (e) => {
    const { email, password } = this.props;
    if (e.keyCode === 13) {
      this.props.login(email, password, this.props.history, this.state.from);
    }
  }

  render() {
    const { navigation } = this.props;
    const {loginError} = this.props;
    const { email, password } = this.props;
    return (
      <div className="theme-blue">
        <div className="page-loader-wrapper" style={{ display: this.state.isLoad ? 'block' : 'none' }}>
          <div className="loader">
            <div className="m-t-30"><img src={require('../assets/images/logo-icon.svg')} width="48" height="48" alt="Lucid" /></div>
            <p>Please wait...</p>
          </div>
        </div>
        <div className="hide-border">
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top align-bottom align-text-bottom flex-fill flex-grow-0">
                  <img className="float-left align-bottom" src={Logo} alt="Dashboard" style={{ height: "40px", margin: "10px" }} />
                  <h2 className="float-left align-bottom" style={{color:"#f8b713", fontStyle: "italic"}}>Dashboard</h2>
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Login to your account</p>
                  </div>
                  <div className="body">
                    <div className="form-auth-small" action="index.html">
                      {this.props.loginError && (
                          <div className="alert alert-danger">{this.props.loginError}</div>
                      )}
                      <div className="form-group">
                        <label className="control-label sr-only">Email</label>
                        <input
                          className="form-control"
                          id="signin-email"
                          placeholder="Email"
                          type="email"
                          value={email}
                          onChange={val => {
                            this.props.updateEmail(val.target.value);
                          }}
                          onKeyUp={this.handleKeyPress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label sr-only">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="signin-password"
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={val => {
                            this.props.updatePassword(val.target.value);
                          }}
                          onKeyUp={this.handleKeyPress}
                        />
                      </div>
                      <div className="form-group clearfix">
                        <label className="fancy-checkbox element-left">
                          <input type="checkbox" />
                          <span>Remember me</span>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={() => {this.props.login(email, password, this.props.history, this.state.from)}}
                        // href='/dashboard'
                      >Login</button>
                      <div className="bottom">
                        <span className="helper-text m-b-10">
                          <i className="fa fa-lock"></i>{" "}
                          <a href={`${process.env.PUBLIC_URL}/forgotpassword`} 
                          >
                            Forgot password?
                          </a>
                        </span>
                        <span>
                          Don't have an account?{" "}
                          <a href="registration" >Register</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  updateEmail: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired
};

const mapStateToProps = ({ loginReducer }) => ({
  email: loginReducer.email,
  password: loginReducer.password,
  loginError: loginReducer.loginError
});

export default connect(mapStateToProps, {
  updateEmail,
  updatePassword,
  onLoggedin,
  login,
  loginSuccess
})(Login);
