import React from "react";
import {connect} from "react-redux";
import * as echarts from "echarts";
import PageHeader from "../../../components/PageHeader";
import {Dropdown} from "react-bootstrap";

import statsApi from "../../../api/stats"

class DailyCharts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dailyRegistrationsData: {},
            dailyRegistrationsOptions: {},
            dailyRegistrationsHowManyDays: 30,
            dailyRegistrationsWhiteLabel: 'All',
            dailySalesData: {},
            dailySalesOptions: {},
            dailySalesWhiteLabel: 'All',
            dailySalesHowManyDays: 30,
            dailyRevenueData: {},
            dailyRevenueOptions: {},
            dailyRevenueHowManyDays: 30,
            dailyRevenueWhiteLabel: 'All',
        };

        this.registrationsChartRef = null;
        this.registrationsChart = null;
        this.salesChartRef = null;
        this.salesChart = null;
        this.setRegistrationsChartRef = element => {
            if (element) {
                this.registrationsChartRef = element;
                this.registrationsChart = echarts.init(this.registrationsChartRef);
            }
        }
        this.setSalesChartRef = element => {
            if (element) {
                this.salesChartRef = element;
                this.salesChart = echarts.init(this.salesChartRef);
            }
        }
        this.setRevenueChartRef = element => {
            if (element) {
                this.revenueChartRef = element;
                this.revenueChart = echarts.init(this.revenueChartRef);
            }
        }

    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initRegistrationsChartData();
        this.initSalesChartData();
        this.initRevenueChartData();
        const title = document.getElementsByTagName('title')[0];
        title.innerHTML = 'Dashboard::User Metrics::Daily Charts'

    };

    initRegistrationsChartData = () => {

        const {token} = this.props;
        statsApi.dailyRegistrations(token, this.state.dailyRegistrationsHowManyDays).then(response => {
            if (response.ok && response.data.data) {
                this.setState({
                    dailyRegistrationsData: response.data.data
                }, () => {
                    this.initRegistrationsChartOptions(response.data.data)
                });
            }
        });
    }

    initSalesChartData = () => {
        const {token} = this.props;
        statsApi.dailySales(token, this.state.dailySalesHowManyDays).then(response => {
            if (response.ok && response.data.data) {
                this.setState({
                    dailySalesData: response.data.data
                }, () => {
                    this.initSalesChartOptions(response.data.data)
                });
            }
        });
    }

    initSalesChartOptions = (salesData) => {
        const series = [];
        Object.keys(salesData.counts).forEach((element, index) => {
            series.push({
                type: "line",
                name: element,
                data: salesData.counts[element],
                symbolSize: 8,
                smooth: true,
            });
        });

        const dailySalesOptions = this.initChartOptions(salesData, series);

        this.setState({
            dailySalesOptions: dailySalesOptions
        }, () => {
            this.setSalesChartOptions();
        });
    }

    setSalesChartOptions = () => {
        this.salesChart.clear();
        this.salesChart.setOption(this.state.dailySalesOptions);
    }

    initRevenueChartData = () => {
        const {dailyRevenueHowManyDays} = this.state;
        const {token} = this.props;
        statsApi.dailyRevenue(token, dailyRevenueHowManyDays).then(response => {
            if (response.ok && response.data.data) {
                this.setState({
                    dailyRevenueData: response.data.data
                }, () => {
                    this.initRevenueChartOptions(response.data.data)
                });
            }
        });
    }
    initRevenueChartOptions = (data) => {
        const series = [];

        Object.keys(data.revenue).forEach((element, index) => {
            series.push({
                type: "line",
                name: element,
                data: data.revenue[element],
                symbolSize: 8,
                smooth: true,
            });
        });

        const options = this.initChartOptions(data, series);

        this.setState({
            dailyRevenueOptions: options
        }, () => {
            this.setRevenueChartOptions();
        });
    }
    setRevenueChartOptions = () => {
        this.revenueChart.clear();
        this.revenueChart.setOption(this.state.dailyRevenueOptions);
    }

    initRegistrationsChartOptions = (registrationData) => {
        const dailyRegistrationsSeries = [];

        Object.keys(registrationData.counts).forEach((element, index) => {
            dailyRegistrationsSeries.push({
                type: "line",
                name: element,
                data: registrationData.counts[element],
                symbolSize: 8,
                smooth: true,
            });
        });

        const dailyRegistrationOptions = this.initChartOptions(registrationData, dailyRegistrationsSeries);


        this.setState({
            dailyRegistrationsOptions: dailyRegistrationOptions
        }, () => {
            this.setRegistrationsChartOptions();
        });
    }

    setRegistrationsChartOptions = () => {
        this.registrationsChart.clear();
        this.registrationsChart.setOption(this.state.dailyRegistrationsOptions);
    }

    initChartOptions = (data, series) => {
        return {
            tooltip: {
                trigger: "axis",
            },
            grid: {
                top: "4%",
                left: "1%",
                right: "1%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    boundaryGap: false,
                    data: data.dates,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#a9a9a9",
                        },
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ["#E0E6F1"],
                        },
                    },

                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: function (value, index) {
                            if (value > 0) {
                                return value;
                            } else {
                                return 0;
                            }
                        },
                        textStyle: {
                            color: "#a9a9a9",
                        },
                    },
                },
            ],
            series: series,
        }
    }


    handleWhiteLabelSelectRegistrations = (whiteLabel) => {
        this.setState({dailyRegistrationsWhiteLabel: whiteLabel});
        if (whiteLabel === 'All') {
            this.initRegistrationsChartOptions(this.state.dailyRegistrationsData)
        } else {

            const singleRegistrationData = {...this.state.dailyRegistrationsData};
            const newCounts = {};
            newCounts[whiteLabel] = singleRegistrationData.counts[whiteLabel];

            singleRegistrationData.counts = newCounts;

            this.initRegistrationsChartOptions(singleRegistrationData);
        }

    }

    handleWhiteLabelSelectSales = (whiteLabel) => {
        this.setState({dailySalesWhiteLabel: whiteLabel});

        if (whiteLabel === 'All') {
            this.initSalesChartOptions(this.state.dailySalesData)
        } else {
            const singleSalesData = {...this.state.dailySalesData};

            const newCounts = {};
            newCounts[whiteLabel] = singleSalesData.counts[whiteLabel];

            singleSalesData.counts = newCounts;

            this.initSalesChartOptions(singleSalesData);
        }

    }
    handleWhiteLabelSelectRevenue = (whiteLabel) => {
        this.setState({dailyRevenueWhiteLabel:whiteLabel})
        if (whiteLabel === 'All') {
            this.initRevenueChartOptions(this.state.dailyRevenueData)
        } else {
            const singleRevenueData = {...this.state.dailyRevenueData};

            const newRevenue = {};
            newRevenue[whiteLabel] = singleRevenueData.revenue[whiteLabel];

            singleRevenueData.revenue = newRevenue;

            this.initRevenueChartOptions(singleRevenueData);
        }
    }

    handleRegistrationsHowManyDaysChange = (howManyDays) => {
        this.setState({
            dailyRegistrationsHowManyDays: howManyDays,
            dailyRegistrationsWhiteLabel: 'All'
        }, () => {
            this.initRegistrationsChartData();
        });
    }
    handleSalesHowManyDaysChange = (howManyDays) => {
        this.setState({
            dailySalesHowManyDays: howManyDays,
            dailySalesWhiteLabel: 'All',
        }, () => {
            this.initSalesChartData();
        });
    }
    handleRevenueHowManyDaysChange = (howManyDays) => {
        this.setState({
            dailyRevenueHowManyDays: howManyDays,
            dailyRevenueWhiteLabel: 'All',
        }, () => {
            this.initRevenueChartData();
        });
    }
    render() {
        const {dailyRegistrationsData, dailySalesData, dailyRevenueData} = this.state;
        let registrationsDropDownOptions = '';
        if (dailyRegistrationsData.counts) {
            registrationsDropDownOptions = Object.keys(dailyRegistrationsData.counts).map((item, i) => (
                <li className="dropdown-item"  key={item} onClick={() => this.handleWhiteLabelSelectRegistrations(item)}><a>{item}</a></li>
            ));
        }
        let salesDropDownOptions = '';
        if (dailySalesData.counts) {
            salesDropDownOptions = Object.keys(dailySalesData.counts).map((item, i) => (
                <li className="dropdown-item"  key={item} onClick={() => this.handleWhiteLabelSelectSales(item)}><a>{item}</a></li>
            ));
        }
        let revenueDropDownOptions = '';
        if (dailyRevenueData.revenue) {
            revenueDropDownOptions = Object.keys(dailyRevenueData.revenue).map((item, i) => (
                <li className="dropdown-item"  key={item} onClick={() => this.handleWhiteLabelSelectRevenue(item)}><a>{item}</a></li>
            ));
        }

        return (
            <div
                style={{flex: 1}}
                onClick={() => {
                    document.body.classList.remove("offcanvas-active");
                }}
            >
                <div>
                    <div className="container-fluid">
                        <PageHeader
                            HeaderText="Dashboard"
                            Breadcrumb={[{name: "User Metrics"}]}
                        />


                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className='float-left'>Daily User Registrations -</h2>
                                        <Dropdown as="span" className="float-right dropdown">
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="h2"
                                                id="dropdown-registrations-white-label"
                                            >
                                                {this.state.dailyRegistrationsWhiteLabel}

                                                <Dropdown.Menu
                                                    as="ul"
                                                >
                                                    <li className="dropdown-item"  onClick={() => this.handleWhiteLabelSelectRegistrations('All')}><a>All</a>
                                                    </li>
                                                    {registrationsDropDownOptions}
                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>
                                        </Dropdown>

                                        <Dropdown as="span" className="float-left">
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="h2"
                                                id="dropdown-registrations-days"
                                            >
                                                - Last {this.state.dailyRegistrationsHowManyDays} Days

                                                <Dropdown.Menu
                                                    as="ul"
                                                    className="dropdown-menu dropdown-menu-left"
                                                >
                                                    <li className="dropdown-item"  onClick={() => this.handleRegistrationsHowManyDaysChange(30)}><a>Last 30 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleRegistrationsHowManyDaysChange(60)}><a>Last 60 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleRegistrationsHowManyDaysChange(90)}><a>Last 90 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleRegistrationsHowManyDaysChange(120)}><a>Last 120 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleRegistrationsHowManyDaysChange(180)}><a>Last 180 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleRegistrationsHowManyDaysChange(360)}><a>Last 360 days</a></li>

                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                    <div className="body gender-overview">
                                        <div id="daily_registrations_chart" ref={this.setRegistrationsChartRef} style={{height: 300}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className='float-left'>Daily First-Time Sales -</h2>
                                        <Dropdown as="span" className="float-right">
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="h2"
                                                id="dropdown-sales-white-label"
                                            >
                                                {this.state.dailySalesWhiteLabel}
                                                <Dropdown.Menu
                                                    as="ul"
                                                    className="dropdown-menu dropdown-menu-right"
                                                >
                                                    <li className="dropdown-item" onClick={() => this.handleWhiteLabelSelectSales('All')}><a>All</a></li>
                                                    {salesDropDownOptions}
                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                        <Dropdown as="span" className="float-left">
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="h2"
                                                id="dropdown-sales-days"
                                            >
                                                - Last {this.state.dailySalesHowManyDays} Days

                                                <Dropdown.Menu
                                                    as="ul"
                                                    className="dropdown-menu dropdown-menu-right"
                                                >
                                                    <li className="dropdown-item" onClick={() => this.handleSalesHowManyDaysChange(30)}><a>Last 30 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleSalesHowManyDaysChange(60)}><a>Last 60 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleSalesHowManyDaysChange(90)}><a>Last 90 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleSalesHowManyDaysChange(120)}><a>Last 120 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleSalesHowManyDaysChange(180)}><a>Last 180 days</a></li>
                                                    <li className="dropdown-item" onClick={() => this.handleSalesHowManyDaysChange(360)}><a>Last 360 days</a></li>

                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>

                                    <div className="body gender-overview">
                                        <div id="daily_sales_chart" ref={this.setSalesChartRef} style={{height: 300}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className='float-left'>Daily Revenue - </h2>
                                        {/*<span className='float-left' style={{minWidth:10, minHeight:10}}></span>*/}
                                        <Dropdown
                                            as="h2"
                                            className="dropdown float-left"
                                        >
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="span"
                                                className="dropdown-toggle"
                                                id="dropdown-revenue-days"
                                            >
                                                - Last {this.state.dailyRevenueHowManyDays} Days

                                                <Dropdown.Menu
                                                    as="ul"
                                                    className="dropdown-menu dropdown-menu-right"
                                                >
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(30)}><a>Last 30 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(60)}><a>Last 60 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(90)}><a>Last 90 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(120)}><a>Last 120 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(180)}><a>Last 180 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(360)}><a>Last 360 days</a></Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                        <Dropdown as="span" className="dropdown float-right">
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="h2"
                                                id="dropdown-revenue-white-label"
                                            >
                                                {this.state.dailyRevenueWhiteLabel}

                                                <Dropdown.Menu
                                                    as="ul"
                                                    className="dropdown-menu dropdown-menu-right"
                                                >
                                                    <li className="dropdown-item" onClick={() => this.handleWhiteLabelSelectRevenue('All')}><a>All</a></li>
                                                    {revenueDropDownOptions}
                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>
                                        </Dropdown>


                                    </div>
                                    <div className="body gender-overview">
                                        <div id="daily_revenue_chart" ref={this.setRevenueChartRef} style={{height: 300}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({loginReducer}) => ({
    isLoggedin: loginReducer.isLoggedin,
    user: loginReducer.user,
    token: loginReducer.token
});

export default connect(mapStateToProps, {})(DailyCharts);
