import React from 'react';
import { connect } from "react-redux";
import { Route, Redirect,withRouter } from 'react-router-dom';

const PrivateRoute = ({component: Component, isLoggedin, ...rest}) =>
{
    return (
        <Route {...rest} render={props => (
            isLoggedin
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    )

};


const mapStateToProps = ({ loginReducer }) => ({
    isLoggedin: loginReducer.isLoggedin,
});


export default withRouter(connect(mapStateToProps,{})(PrivateRoute));