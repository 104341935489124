let baseUrl = "https://dev-admin-api.celeb.tv";
switch (process.env.REACT_APP_ENV) {
    case 'local':
        baseUrl = 'http://localhost:8003';
        break;
    case 'rc':
        baseUrl = 'https://rc-admin-api.celeb.tv';
        break;
    case 'prod':
    case 'production':
        baseUrl = 'https://admin-api.celeb.tv';
        break;
}

export default Object.freeze({
    BASE_URL: baseUrl,
    API_PATH: "/api/v1",
    ENDPOINTS: {
        AUTH: '/auth',
        DAILY_REGISTRATIONS_CHART: '/stats/chart/reg/daily',
        DAILY_SALES_CHART: '/stats/chart/sales/daily',
        DAILY_REVENUE_CHART: '/stats/chart/revenue/daily',
        DAILY_SPENT_CHART: '/stats/chart/spent/daily',
        DAILY_SUBSCRIPTION_REVENUE_CHART: '/stats/chart/subscription/revenue/daily',
        DAILY_NEW_SUBSCRIPTIONS_COUNT_CHART: '/stats/chart/subscription/new/daily',
        DAILY_SUBSCRIPTION_CANCELLATIONS_CHART: '/stats/chart/subscription/cancelled/daily',
    }
})