import authApi from "../api/auth"
import { createBrowserHistory } from 'history';
export const UPDATE_EMAIL = "loginReducer/UPDATE_EMAIL";
export const UPDATE_PASSWORD = "loginReducer/UPDATE_PASSWORD";
export const ON_LOGGEDIN = "loginReducer/ON_LOGGEDIN";
export const LOGIN_REQUEST = 'loginReducer/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'loginReducer/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'loginReducer/LOGIN_FAILURE';


export const updateEmail = (val) => (disptch) => {
  disptch({
    type: UPDATE_EMAIL,
    payload: val,
  });
};

export const updatePassword = (val) => (disptch) => {
  disptch({
    type: UPDATE_PASSWORD,
    payload: val,
  });
};

export const onLoggedin = (val) => (disptch) => {
  disptch({
    type: ON_LOGGEDIN,
    payload: val,
  });
};

export const login = (username, password, history, to) => (disptch) => {
  disptch(loginRequest(username, password));

  authApi.login(username,password).then(response => {
    if(response.ok) {
      disptch(loginSuccess(response.data.token));
      history.push(to);
    } else {
      disptch(loginFailure(response));
    }
  });
}
export const loginSuccess = (token) => {
  localStorage.setItem('token', JSON.stringify(token));
  return { type: LOGIN_SUCCESS, token: token}
}
const loginRequest = (username, password) => {
  return {type: LOGIN_REQUEST, user: {username, password}}
}

const loginFailure = (response) => {
  return {type: LOGIN_FAILURE}
}
