import React from "react";
import {connect} from "react-redux";
import * as echarts from "echarts";
import PageHeader from "../../../components/PageHeader";
import {Dropdown} from "react-bootstrap";

import statsApi from "../../../api/stats"
import LogoiCON from "../../../assets/images/logo-icon.svg";

class DailyCharts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dailyRevenueData: {},
            dailyRevenueOptions: {},
            dailyRevenueHowManyDays: 30,
            dailyRevenueWhiteLabel: 'All',
            dailyRevenueIsLoaded: false,
            dailyNewSubscriptionsData: {},
            dailyNewSubscriptionsOptions: {},
            dailyNewSubscriptionsHowManyDays: 30,
            dailyNewSubscriptionsWhiteLabel: 'All',
            dailyNewSubscriptionsIsLoaded: false,
            dailyCancelData: {},
            dailyCancelOptions: {},
            dailyCancelHowManyDays: 30,
            dailyCancelWhiteLabel: 'All',
            dailyCancelIsLoaded: false,
        };

        this.revenueChartRef = null;
        this.revenueChart = null;
        this.setRevenueChartRef = element => {
            if (element) {
                this.revenueChartRef = element;
                this.revenueChart = echarts.init(this.revenueChartRef);
            }
        }

        this.newSubscriptionsChartRef = null;
        this.newSubscriptionsChart = null;
        this.setNewSubscriptionsChartRef = element => {
            if (element) {
                this.newSubscriptionsChartRef = element;
                this.newSubscriptionsChart = echarts.init(this.newSubscriptionsChartRef);
            }
        }

        this.cancelChartRef = null;
        this.cancelChart = null;
        this.setCancelChartRef = element => {
            if (element) {
                this.cancelChartRef = element;
                this.cancelChart = echarts.init(this.cancelChartRef);
            }
        }

    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initRevenueChartData();
        this.initNewSubscriptionsChartData();
        this.initCancelChartData();
        const title = document.getElementsByTagName('title')[0];
        title.innerHTML = 'Dashboard::Subscriptions::Daily Charts'

    };

    initRevenueChartData = () => {
        this.setState({dailyRevenueIsLoaded: false}, () => {
            const {dailyRevenueHowManyDays} = this.state;
            const {token} = this.props;
            statsApi.dailySubscriptionRevenue(token, dailyRevenueHowManyDays).then(response => {
                if (response.ok && response.data.data) {
                    this.setState({
                        dailyRevenueData: response.data.data,
                    }, () => {
                        this.initRevenueChartOptions(response.data.data)
                    });
                }
            });
        });
    }
    initRevenueChartOptions = (dailyRevenueData) => {

        const series = [];

        Object.keys(dailyRevenueData.revenue).forEach((element, index) => {
            series.push({
                type: "line",
                name: element,
                data: dailyRevenueData.revenue[element],
                symbolSize: 8,
                smooth: true,
            });
        });

        const options = this.initChartOptions(dailyRevenueData.dates, series, 'usd');

        this.setState({
            dailyRevenueOptions: options,
            dailyRevenueIsLoaded: true,
        }, () => {
            this.setRevenueChartOptions()
        });
    }
    setRevenueChartOptions = () => {
        if (this.revenueChart) {
            this.revenueChart.clear();
            this.revenueChart.setOption(this.state.dailyRevenueOptions);
        }
    }

    handleWhiteLabelSelectRevenue = (whiteLabel) => {
        this.setState({dailyRevenueWhiteLabel:whiteLabel})
        if (whiteLabel === 'All') {
            this.initRevenueChartOptions(this.state.dailyRevenueData)
        } else {
            const singleRevenueData = {...this.state.dailyRevenueData};

            const newRevenue = {};
            newRevenue[whiteLabel] = singleRevenueData.revenue[whiteLabel];

            singleRevenueData.revenue = newRevenue;

            this.initRevenueChartOptions(singleRevenueData);
        }
    }

    handleRevenueHowManyDaysChange = (howManyDays) => {
        this.setState({
            dailyRevenueHowManyDays: howManyDays,
            dailyRevenueWhiteLabel: 'All',
        }, () => {
            this.initRevenueChartData();
        });
    }

    /**** NEW SUBSCRIPTIONS COUNTS ***/

    initNewSubscriptionsChartData = () => {
        this.setState({dailyNewSubscriptionsIsLoaded: false}, () => {
            const {dailyNewSubscriptionsHowManyDays} = this.state;
            const {token} = this.props;
            statsApi.dailyNewSubscriptionsCount(token, dailyNewSubscriptionsHowManyDays).then(response => {
                if (response.ok && response.data.data) {
                    this.setState({
                        dailyNewSubscriptionsData: response.data.data,
                    }, () => {
                        this.initNewSubscriptionsChartOptions(response.data.data);
                    });
                }
            });
        });
    }
    initNewSubscriptionsChartOptions = (data) => {

        const series = [];

        Object.keys(data.counts).forEach((element, index) => {
            series.push({
                type: "line",
                name: element,
                data: data.counts[element],
                symbolSize: 8,
                smooth: true,
            });
        });

        const options = this.initChartOptions(data.dates, series);

        this.setState({
            dailyNewSubscriptionsOptions: options,
            dailyNewSubscriptionsIsLoaded: true,
        }, () => {
             this.setNewSubcriptionsChartOptions();
        });
    }
    setNewSubcriptionsChartOptions = () => {
        this.newSubscriptionsChart.clear();
        this.newSubscriptionsChart.setOption(this.state.dailyNewSubscriptionsOptions);
    }

    handleWhiteLabelSelectNewSubscriptions = (whiteLabel) => {
        this.setState({dailyNewSubscriptionsWhiteLabel:whiteLabel})
        if (whiteLabel === 'All') {
            this.initRevenueChartOptions(this.state.dailyNewSubscriptionsData)
        } else {
            const singleRevenueData = {...this.state.dailyNewSubscriptionsData};

            const newCounts = {};
            newCounts[whiteLabel] = singleRevenueData.counts[whiteLabel];

            singleRevenueData.counts = newCounts;

            this.initNewSubscriptionsChartOptions(singleRevenueData);
        }
    }

    handleNewSubscriptionsHowManyDaysChange = (howManyDays) => {
        this.setState({
            dailyNewSubscriptionsHowManyDays: howManyDays,
            dailyNewSubscriptionsWhiteLabel: 'All',
        }, () => {
            this.initNewSubscriptionsChartData();
        });
    }

    /*** END NEW SUBSCRIPTIONS COUNTS ***/


    /*** CANCELLATIONS ***/

    initCancelChartData = () => {
        this.setState({dailyCancelIsLoaded: false}, () => {
            const {dailyCancelHowManyDays} = this.state;
            const {token} = this.props;
            statsApi.dailySubscriptionsCancelledCount(token, dailyCancelHowManyDays).then(response => {
                if (response.ok && response.data.data) {
                    this.setState({
                        dailyCancelData: response.data.data,
                    }, () => {
                        this.initCancelChartOptions(response.data.data);
                    });
                }
            });
        });
    }
    initCancelChartOptions = (data) => {

        const series = [];

        Object.keys(data.counts).forEach((element, index) => {
            series.push({
                type: "line",
                name: element,
                data: data.counts[element],
                symbolSize: 8,
                smooth: true,
            });
        });

        const options = this.initChartOptions(data.dates, series);

        this.setState({
            dailyCancelOptions: options,
            dailyCancelIsLoaded: true,
        }, () => {
            this.setCancelChartOptions();
        });
    }
    setCancelChartOptions = () => {
        if ( this.cancelChart) {
            this.cancelChart.clear();
            this.cancelChart.setOption(this.state.dailyCancelOptions);
        }
    }

    handleWhiteLabelSelectCancel = (whiteLabel) => {
        this.setState({dailyCancelWhiteLabel:whiteLabel})
        if (whiteLabel === 'All') {
            this.initCancelChartOptions(this.state.dailyCancelData)
        } else {
            const singleData = {...this.state.dailyCancelData};

            const newCounts = {};
            newCounts[whiteLabel] = singleData.counts[whiteLabel];

            singleData.counts = newCounts;

            this.initCancelChartOptions(singleData);
        }
    }

    handleCancelHowManyDaysChange = (howManyDays) => {
        this.setState({
            dailyCancelHowManyDays: howManyDays,
            dailyCancelWhiteLabel: 'All',
        }, () => {
            this.initCancelChartData();
        });
    }

    /*** END CANCELLATIONS ***/

    initChartOptions = (dates, series, numberFormat='number') => {
        return {
            tooltip: {
                trigger: "axis",
                formatter: (params) => {
                    let output = '<div style="font-weight: bolder; text-align: center">' + params[0].axisValueLabel + '</div>';
                    for (let i=0; i < params.length; i++) {
                        let formatedValue = '0';
                        if (numberFormat == 'usd') {
                            formatedValue = '0.00';
                        }
                        if (params[i].data > 0) {
                            if (params[i].data > 1000000) {
                                formatedValue =  new Intl.NumberFormat('en-EN').format(params[i].data / 1000000) + ' M';
                            }
                            else if (params[i].data > 1000) {
                                formatedValue =  new Intl.NumberFormat('en-EN').format(params[i].data / 1000) + ' K';
                            }
                            else {
                                formatedValue = new Intl.NumberFormat('en-EN').format(params[i].data)
                            }
                        }

                        if (numberFormat === 'usd') {
                            formatedValue = '$ ' + formatedValue;
                        }
                        let line = params[i].marker +
                            '<span style="width:100%; display: inline-block;">' +
                            params[i].seriesName + ': ' + '<span style="float:right; margin-right: 20px">' +  formatedValue +'</span> </span><br/>'
                        output += line;
                    }
                    return output;
                }
            },
            grid: {
                top: "4%",
                left: "1%",
                right: "1%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    boundaryGap: false,
                    data: dates,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#a9a9a9",
                        },
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ["#E0E6F1"],
                        },
                    },

                    axisLine: {
                        show: false,
                    },
                    axisLabel: {

                        formatter: (value) => {
                            let returnValue = '0';
                            if (value > 0) {
                                if (value > 1000000) {
                                    returnValue =  new Intl.NumberFormat('en-EN').format(value / 1000000) + ' M';
                                }
                                else if (value > 1000) {
                                    returnValue =  new Intl.NumberFormat('en-EN').format(value / 1000) + ' K';
                                }
                                else {
                                    returnValue = new Intl.NumberFormat('en-EN').format(value)
                                }
                            }

                            if (numberFormat === 'usd') {
                                returnValue = '$ ' + returnValue;
                            }
                            return returnValue;
                        },
                        textStyle: {
                            color: "#a9a9a9",
                        },
                    },
                },
            ],
            series: series,
        }
    }

    render() {
        const {dailyRevenueData, dailyNewSubscriptionsData, dailyCancelData} = this.state;
        let revenueDropDownOptions = '';
        if (dailyRevenueData.revenue) {
            revenueDropDownOptions = Object.keys(dailyRevenueData.revenue).map((item, i) => (
                <li className="dropdown-item"  key={item} onClick={() => this.handleWhiteLabelSelectRevenue(item)}><a>{item}</a></li>
            ));
        }

        let newSubscriptionsDropDownOptions = '';
        if (dailyNewSubscriptionsData.counts) {
            newSubscriptionsDropDownOptions = Object.keys(dailyNewSubscriptionsData.counts).map((item, i) => (
                <li className="dropdown-item"  key={item} onClick={() => this.handleWhiteLabelSelectNewSubscriptions(item)}><a>{item}</a></li>
            ));
        }
        let dailyCancelDropDownOptions = '';
        if (dailyCancelData.counts) {
            dailyCancelDropDownOptions = Object.keys(dailyCancelData.counts).map((item, i) => (
                <li className="dropdown-item" key={item} onClick={() => this.handleWhiteLabelSelectCancel(item)}>
                    <a>{item}</a>
                </li>
            ))
        }


        const loader =
            <div className="loader" style={{textAlign: 'center',height: 300 }}>
                <div className="m-t-30">
                    <img src={LogoiCON} width="48" height="48" alt="Lucid" />
                </div>
                <p><i className="fa fa-spinner fa-spin"></i>    </p>
                <p>Please wait...</p>
            </div>;

        return (
            <div
                style={{flex: 1}}
                onClick={() => {
                    document.body.classList.remove("offcanvas-active");
                }}
            >
                <div>
                    <div className="container-fluid">
                        <PageHeader
                            HeaderText="Dashboard"
                            Breadcrumb={[{name: "Subscriptions"}]}
                        />

                        { !this.state.dailyRevenueIsLoaded && (loader) }
                        { this.state.dailyRevenueIsLoaded && (
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className='float-left'>Daily Revenue - </h2>
                                        {/*<span className='float-left' style={{minWidth:10, minHeight:10}}></span>*/}
                                        <Dropdown
                                            as="h2"
                                            className="dropdown float-left"
                                        >
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="span"
                                                className="dropdown-toggle"
                                                id="dropdown-revenue-days"
                                            >
                                                - Last {this.state.dailyRevenueHowManyDays} Days

                                                <Dropdown.Menu
                                                    as="ul"
                                                    className="dropdown-menu dropdown-menu-right"
                                                >
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(30)}><a>Last 30 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(60)}><a>Last 60 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(90)}><a>Last 90 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(120)}><a>Last 120 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(180)}><a>Last 180 days</a></Dropdown.Item>
                                                    <Dropdown.Item as='li' onClick={() => this.handleRevenueHowManyDaysChange(360)}><a>Last 360 days</a></Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                        <Dropdown as="span" className="dropdown float-right">
                                            <Dropdown.Toggle
                                                variant="success"
                                                as="h2"
                                                id="dropdown-revenue-white-label"
                                            >
                                                {this.state.dailyRevenueWhiteLabel}

                                                <Dropdown.Menu
                                                    as="ul"
                                                    className="dropdown-menu dropdown-menu-right"
                                                >
                                                    <li className="dropdown-item" onClick={() => this.handleWhiteLabelSelectRevenue('All')}><a>All</a></li>
                                                    {revenueDropDownOptions}
                                                </Dropdown.Menu>
                                            </Dropdown.Toggle>
                                        </Dropdown>


                                    </div>
                                    <div className="body gender-overview">
                                        <div id="daily_revenue_chart" ref={this.setRevenueChartRef} style={{height: 300}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        )}


                        { !this.state.dailyNewSubscriptionsIsLoaded && (

                            loader

                        )}

                        { this.state.dailyNewSubscriptionsIsLoaded && (
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="header">
                                            <h2 className='float-left'>New Daily Subscriptions Count - </h2>
                                            {/*<span className='float-left' style={{minWidth:10, minHeight:10}}></span>*/}
                                            <Dropdown
                                                as="h2"
                                                className="dropdown float-left"
                                            >
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    as="span"
                                                    className="dropdown-toggle"
                                                    id="dropdown-revenue-days"
                                                >
                                                    - Last {this.state.dailyNewSubscriptionsHowManyDays} Days

                                                    <Dropdown.Menu
                                                        as="ul"
                                                        className="dropdown-menu dropdown-menu-right"
                                                    >
                                                        <Dropdown.Item as='li' onClick={() => this.handleNewSubscriptionsHowManyDaysChange(30)}><a>Last 30 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleNewSubscriptionsHowManyDaysChange(60)}><a>Last 60 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleNewSubscriptionsHowManyDaysChange(90)}><a>Last 90 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleNewSubscriptionsHowManyDaysChange(120)}><a>Last 120 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleNewSubscriptionsHowManyDaysChange(180)}><a>Last 180 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleNewSubscriptionsHowManyDaysChange(360)}><a>Last 360 days</a></Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown.Toggle>

                                            </Dropdown>
                                            <Dropdown as="span" className="dropdown float-right">
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    as="h2"
                                                    id="dropdown-revenue-white-label"
                                                >
                                                    {this.state.dailyNewSubscriptionsWhiteLabel}

                                                    <Dropdown.Menu
                                                        as="ul"
                                                        className="dropdown-menu dropdown-menu-right"
                                                    >
                                                        <li className="dropdown-item" onClick={() => this.handleWhiteLabelSelectNewSubcriptions('All')}><a>All</a></li>
                                                        {newSubscriptionsDropDownOptions}
                                                    </Dropdown.Menu>
                                                </Dropdown.Toggle>
                                            </Dropdown>


                                        </div>
                                        <div className="body gender-overview">
                                            <div id="daily_new_subscriptions_chart" ref={this.setNewSubscriptionsChartRef} style={{height: 300}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        { !this.state.dailyCancelIsLoaded && (loader) }
                        { this.state.dailyCancelIsLoaded && (
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="header">
                                            <h2 className='float-left'>Daily Cancellations - </h2>
                                            {/*<span className='float-left' style={{minWidth:10, minHeight:10}}></span>*/}
                                            <Dropdown
                                                as="h2"
                                                className="dropdown float-left"
                                            >
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    as="span"
                                                    className="dropdown-toggle"
                                                    id="dropdown-revenue-days"
                                                >
                                                    - Last {this.state.dailyCancelHowManyDays} Days

                                                    <Dropdown.Menu
                                                        as="ul"
                                                        className="dropdown-menu dropdown-menu-right"
                                                    >
                                                        <Dropdown.Item as='li' onClick={() => this.handleCancelHowManyDaysChange(30)}><a>Last 30 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleCancelHowManyDaysChange(60)}><a>Last 60 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleCancelHowManyDaysChange(90)}><a>Last 90 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleCancelHowManyDaysChange(120)}><a>Last 120 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleCancelHowManyDaysChange(180)}><a>Last 180 days</a></Dropdown.Item>
                                                        <Dropdown.Item as='li' onClick={() => this.handleCancelHowManyDaysChange(360)}><a>Last 360 days</a></Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown.Toggle>

                                            </Dropdown>
                                            <Dropdown as="span" className="dropdown float-right">
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    as="h2"
                                                    id="dropdown-revenue-white-label"
                                                >
                                                    {this.state.dailyCancelWhiteLabel}

                                                    <Dropdown.Menu
                                                        as="ul"
                                                        className="dropdown-menu dropdown-menu-right"
                                                    >
                                                        <li className="dropdown-item" onClick={() => this.handleWhiteLabelSelectCancel('All')}><a>All</a></li>
                                                        {dailyCancelDropDownOptions}
                                                    </Dropdown.Menu>
                                                </Dropdown.Toggle>
                                            </Dropdown>


                                        </div>
                                        <div className="body gender-overview">
                                            <div id="daily_cancel_chart" ref={this.setCancelChartRef} style={{height: 300}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({loginReducer}) => ({
    isLoggedin: loginReducer.isLoggedin,
    user: loginReducer.user,
    token: loginReducer.token
});

export default connect(mapStateToProps, {})(DailyCharts);
